import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import carPlateKeyboard from 'car-plate-keyboard';
import 'car-plate-keyboard/lib/carPlateKeyboard.css';
import request from './utils/request'

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(carPlateKeyboard);
Vue.prototype.$axios = request;

new Vue({
    render: h => h(App),
}).$mount('#app')
