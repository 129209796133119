<template>
  <div id="app">
    <el-container>
      <el-header>电子发票开具</el-header>
    </el-container>

    <el-form :model="form" :rules="rules" ref="form" label-width="120px">
      <el-form-item label="公司/个人名称" prop="name" size="medium">
        <el-input v-model="form.name" placeholder="请输入公司/个人名称"></el-input>
      </el-form-item>

      <el-form-item label="" prop="codes" size="medium">
        <label class=" bti">纳税人识别号/身份证号</label>
        <el-input v-model="form.codes" placeholder="请输入纳税人识别号/身份证号" maxlength="18"></el-input>
      </el-form-item>

      <el-form-item label="手机号码" prop="phone" size="medium">
        <el-input v-model="form.phone" placeholder="请输入手机号码" maxlength="11"></el-input>
      </el-form-item>

      <el-form-item label="电子邮箱" prop="email" size="medium">
        <el-input v-model="form.email" placeholder="请输入电子邮箱"></el-input>
      </el-form-item>

      <el-form-item label="停场车牌号" prop="carPlate" size="medium">
        <car-plate-keyboard @submit="submit" @cancel="cancel">
          <!-- <el-input v-model="form.carPlate"></el-input> -->
          <template #backspace> 删除 </template>
        </car-plate-keyboard>
      </el-form-item>

      <el-form-item label="发票开具备注" prop="remarks">
        <el-input type="textarea" v-model="form.remarks"></el-input>
      </el-form-item>
    </el-form>

    <el-button type="primary" :loading="loading" round @click="submitForm" class="btn">提交</el-button>

    <div class="tip">
      <p>温馨提示：</p>
      <p>1.<strong style="color: red;"> * </strong>号为必填项目</p>
      <p>2. 为及时收到发票，请准确填写您的个人信息</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    return {
      form: {
        name: '',
        codes: '',
        phone: '',
        email: '',
        carPlate: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入公司/个人名称', trigger: 'blur' }
        ],
        codes: [
          { required: true, message: '请输入纳税人识别号/身份证号', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入电子邮箱', trigger: 'blur' }
        ],
        carPlate: [
          { required: true, message: '请输入车辆车牌号', trigger: 'change' }
        ]
      },
      // 提交状态
      loading: false,
    }
  },

  created: function () {

  },

  methods: {
    // 提交
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios.post('/save', this.form).then(res => {
            if (res.data.code === 0) {
              this.loading = false;
              this.$message({
                message: res.data.message,
                type: 'success',
                center: true,
              });
              setTimeout(function () {
                window.location.reload();
              }, 3000)
            } else {
              this.loading = false;
              this.$message.error(res.data.message);
            }
          });
        } else {
          return false;
        }
      });
    },

    // 完成
    submit(value) {
      this.form.carPlate = value;
    },

    // 取消
    cancel(value) {
      this.form.carPlate = value;
    }
  }
}
</script>

<style scoped>
.bti {
  width: 120px;
  line-height: 20px;
  margin-left: -120px;
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 18px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.bti:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.el-header {
  color: #333;
  text-align: center;
  line-height: 50px;
  font-weight: 700;
}

.btn {
  width: 100%;
}
.data-show {
  padding-top: 5px;
  font-size: 14px;
  line-height: 20px;
}

.el-inputs {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px 0;
  color: #909399;
  font-size: 14px;
}

.el-message {
  min-width: 100px;
  top: 30px;
}
</style>
